<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Surat Pesanan (PO)</b> yang ada di {{ clinic_name }}
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/inventory/purchase-order/add')"
        v-if="manipulateBtn"
        >Tambah</b-button
      >
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between mt-n3">
              <div :class="'col-md-6'">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <template #append>
                      <b-button
                        squared
                        @click="filterByPeriode"
                        variant="success"
                        >Cari</b-button
                      >
                      <b-button
                        squared
                        @click="resetFilterPeriode"
                        variant="danger"
                        >Reset</b-button
                      >
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <b-button
                            squared
                            @click="resetFilterPeriode"
                            variant="danger"
                            >Reset</b-button
                          >
                          <b-button
                            squared
                            @click="filterByPeriode"
                            variant="success"
                            >Cari</b-button
                          >
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.po_no"
                    placeholder="Cari Berdasar No. PO"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({
                      path: '/inventory/purchase-order/detail/' + data.item.id,
                    })
                  "
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="
                    $router.push({
                      path: '/inventory/purchase-order/edit/' + data.item.id,
                    })
                  "
                  v-if="manipulateBtn"
                >
                  <i class="fas fa-edit px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="manipulateBtn"
                >
                  <i class="fas fa-trash px-0"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debouncer } from "@/core/modules/Helper.js";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      // Filter
      filter: {
        po_no: "",
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "po_no",
          label: "No PO",
          sortable: true,
        },
        {
          key: "vendor_name",
          label: "Kepada",
          sortable: true,
        },
        {
          key: "discount",
          label: "Diskon(%)",
          sortable: true,
        },
        {
          key: "ppn",
          label: "PPn(%)",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      manipulateBtn: false,
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&po_no=${this.filter.po_no}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "inventory-purchase-orders",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      debouncer(() => {
        this.pagination();
      }, 500);
    },

    filterByPeriode() {
      this.pagination();
    },

    resetFilterPeriode() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("inventory-purchase-orders/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1407") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Surat Pesanan (PO)" }]);
    // Get Data
    this.pagination();
    this.setActiveMenu();
  },
};
</script>
